<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("equipmentTypes") }}</h1>
      <br>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>
        </div>
        </div>
    
          <div v-for="equipmentTypes_up in equipmentTypes_up" :key="equipmentTypes_up" class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card>
                    <div slot="no-body">
                       <img v-if="equipmentTypes_up.picture !== null" style="height: 300px !important;" :src= " $store.state.image_url+equipmentTypes_up.picture"  alt="content-img" class="responsive card-img-top">
                       <img v-if="equipmentTypes_up.picture ===null" style="height: 300px !important;" src="@/assets/images/logo/logoprescription.png" alt="content-img" class="responsive card-img-top">
                    </div>
                    <h6 class="mb-2">{{ equipmentTypes_up.name}}{{ ' ' }}({{ equipmentTypes_up.norm}})</h6>
<!--                     <p class="text-grey">{{equipmentTypes_up.created_at | moment}}</p>
 -->                    <div class="flex justify-between flex-wrap">
                        <vs-button  @click="editData(equipmentTypes_up)" class="mt-4 shadow-lg" type="gradient" color="#7367F0" gradient-color-secondary="#CE9FFC">{{ $t("Modifier") }}</vs-button>
                        <vs-button @click="deleteData(equipmentTypes_up.id)" class="mt-4" type="border" color="#b9b9b9">{{ $t("Supprimer") }}</vs-button>
                    </div>
                </vx-card>
          </div>
          
      </div>
     
      <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
      <vs-table  v-if="false" ref="table" pagination :max-items="itemsPerPage" search :data="equipmentTypes">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
            </div>
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ equipmentTypes.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : equipmentTypes.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="name">{{ $t("equipmentTypes") }}</vs-th>
          <vs-th sort-key="norm">{{ $t("norm") }}</vs-th>
<!--           <vs-th sort-key="company">{{ $t("company") }}</vs-th>
 -->          <vs-th sort-key="created_at">{{ $t("created_at") }}</vs-th>
          <vs-th sort-key="updated_at">{{ $t("updated_at") }}</vs-th>
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.name}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.norm}}</p>
            </vs-td>
            <!-- <vs-td>
              <p class="product-name font-medium truncate">{{ tr.company !== null ? tr.company.name : '-'}}</p>
            </vs-td> -->
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.created_at | moment}}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{tr.updated_at | moment}}</p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex">
                <vx-tooltip :text="$t('Voir')" color="success">
                  <feather-icon
                    style="color: #50c00e"
                    icon="ImageIcon"
                    svgClasses="w-5 h-5 hover:text-success stroke-current"
                    class="mr-2"
                    @click="ShowData(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip :text="$t('Modifier')" color="warning">
                  <feather-icon
                    style="color: #FF9F43"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-warning stroke-current"
                    class="mr-2"
                    @click="editData(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip :text="$t('Supprimer')" color="danger">
                  <feather-icon
                    @click="deleteData(tr.id)"
                    style="color: red"
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="mr-2"
                  />
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </template>
<script>
  
import DataViewSidebar from './DataViewSidebarEquipmentType.vue'
//const datasource = require('@/assets/images/logo/logoprescription.png')
export default {
  components: {
    DataViewSidebar
  },
  data () {
    return {
      equipmentTypes_up:[],
      equipmentTypes: [],
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.equipmentTypes.length
    }
  },
  methods: {
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
  
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`equipmentTypes/${id}/`)
            .then(response => {
              this.getAllequipmentTypes()
              window.getPrendTaCom.success(this.$t('SuppressionequipmentTypes'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })
  
    },
    ShowData (tr) {
      //window.open(`${this.$http.defaults.baseURL  }storage/fiche_de_besoin/${id}.pdf`, '_blank')
      window.open(`https://dev.jra.mitigarisk.com/digiraDev/${(tr.picture).substring(49)}`, '_blank')
      
    },
    editData (data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
  
    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
  
    getAllequipmentTypes () {
      this.$vs.loading()
      let imageFormat = ''
      this.$http.get('equipmentTypes/')
        .then((response) => {
          this.equipmentTypes = response.data
          this.equipmentTypes_up = []
          
          this.equipmentTypes.forEach(event => {
            if (event.picture === null) {
              imageFormat = null
            } else {
              imageFormat = (event.picture).substring(37)
            }
            this.equipmentTypes_up.push({
              id: event.id,
              name:event.name,
              norm :event.norm,
              picture:imageFormat,
              created_at :event.created_at,
              updated_at :event.updated_at
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.equipmentTypes = this
    this.getAllequipmentTypes()

  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  
  
  <style scoped>
  
  </style>
  
  <style scoped>
  
  </style>
  